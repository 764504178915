class TrackingApi {
    constructor(eventBus, debug) {
        this.eventBus = eventBus;
        this.debug = debug;
    }

    _debugLog(message, payload) {
        if (this.debug === true) {
            /*                                                 */
            /*                                  */
            console.log(message, payload);
        }
    }

    trackView(payload) {
        if (this.eventBus) {
            this.eventBus.emit('tracking.bct.addFeaturesToPageImpression', payload);
            this._debugLog('ddt feature tracking', payload);
        } else {
            this._debugLog('tracking bct object is not available for ddt view tracking');
        }
    }

    trackClick(clickTrackingData, singleClickProductTrackingData) {
        if (this.eventBus) {
            const additionalInformationPayload = {
                "wk.promo_AttributionFeature": "DailyDeal_Storefront",
                "wk.promo_AttributionSource": "DailyDeal"
            };

            const onNextPageImpressionPayload = { promo_Click: "DailyDeal" }

            this.eventBus.emit('tracking.bct.submitMoveAction', additionalInformationPayload, {
                name: 'click',
                features: [clickTrackingData, singleClickProductTrackingData]
            });
            this._debugLog('ddt tracking event on submit move action', clickTrackingData, singleClickProductTrackingData);

            this.eventBus.emit('tracking.bct.trackOnNextPageImpression', onNextPageImpressionPayload);
            this._debugLog('ddt tracking event on next page impression', onNextPageImpressionPayload);
        } else {
            this._debugLog('tracking bct object is not available for ddt click tracking');
        }
    }
}

export {TrackingApi};

