import {generate_tile_id} from "./trackingIdentifier.js";

const trackingStatus = {
    loaded: 'loaded',
    clicked: 'clicked'
}

class Tracker {
    constructor(trackingApi) {
        const dailyDeal = document.getElementsByClassName("ddt")[0];
        const MASDiv = document.querySelector("div[data-module-type=DAILY_DEAL]");
        const featureSequence = MASDiv ? MASDiv.getAttribute("data-feature-order") || "99" : "99";
        const variationId = dailyDeal.getAttribute("data-variation-id")
        const parentId = generate_tile_id()
        const defaultTrackingData = {
            id: parentId,
            name: 'DailyDeal',
            position: parseInt(featureSequence),
            labels: {
                promo_Feature: true
            }
        };

        const defaultProductTrackingData = {
            id: parentId+'_1',
            variationId: variationId,
            name: 'DailyDeal-Product',
            parentId: parentId,
            position: 1,
            labels: {
                promo_Source: ['DailyDeal']
            }
        };
        const singleViewTrackingData = this.enrichStatus(defaultTrackingData, trackingStatus.loaded);
        const singleViewProductTrackingData = this.enrichStatus(defaultProductTrackingData, trackingStatus.loaded);
        const viewTrackingData = [singleViewTrackingData, singleViewProductTrackingData];
        trackingApi.trackView(viewTrackingData);

        const clickTrackingData = this.enrichStatus(defaultTrackingData, trackingStatus.clicked);
        const singleClickProductTrackingData = this.enrichStatus(defaultProductTrackingData, trackingStatus.clicked);

        dailyDeal.addEventListener("click", () => {
            trackingApi.trackClick(clickTrackingData, singleClickProductTrackingData);
        });

    }

    enrichStatus(defaultTrackingData, status) {
        return Object.assign({status}, defaultTrackingData);
    }
}

export {Tracker};